// https://codepen.io/chriscoyier/pen/xxVBqEg

const animateValue = (obj, start, end, duration) => {
  let startTimestamp = null;

  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
};

// https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport

function isElementVisible(el) {
  var rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = function (x, y) {
      return document.elementFromPoint(x, y);
    };

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  )
    return false;

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  );
}

const subsOdometer = document.querySelectorAll(".animate-number");

function numberCountHandler() {
  subsOdometer.forEach((ex) => {
    setTimeout(function () {
      ex.innerHTML = ex.dataset.end;
    });
  });
}

function createAnimations() {
  const controller = new ScrollMagic.Controller();

  if (window.matchMedia("(min-width: 1200px)").matches) {
    var tween9 = new TimelineMax()
      .from("#two", 2, { y: "700%" }, "+=2")
      .from("#three", 2, { y: "700%" }, "+=2")
      .from("#four", 2, { y: "700%" }, "+=2");

    var ourServices = new ScrollMagic.Scene({
      duration: 2000,
      triggerElement: "#fazemos",
      offset: -90,
    })
      .setTween(tween9)
      .setPin("#fazemos")
      .triggerHook(0)
      .addTo(controller);
  }

  // const tweenCards = TweenMax.staggerFrom(".clients", 1, { opacity: 0 }, 2);

  // const cards = new ScrollMagic.Scene({
  //   triggerElement: "#clientes",
  //   duration: 3000,
  //   offset: -50,
  // })
  //   .setTween(tweenCards)
  //   .setPin("#clientes")
  //   .triggerHook(0)
  //   .addTo(controller);

  var tween8 = new TimelineMax()
    .from(".problem .center-content", 1, { scale: 0, opacity: 0 }, "+=2")

    .from(".solutions", 2, { scale: 0, opacity: 0 }, "+=6")
    .from(".solutions .center-content", 6, { scale: 0, opacity: 0 }, "+=3");

  // build scene
  var scene8 = new ScrollMagic.Scene({
    triggerElement: "#pin-blocks",
    duration: 3000,
  })
    .setPin("#pin-blocks")
    .setTween(tween8)
    .triggerHook(0)
    .addTo(controller);
}

function topFunction() {
  window.scrollTo({
    top: 0,
    left:0,
    behavior: "smooth"
  });
  console.log("teste");
}
