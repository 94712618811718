const init = () => {
  new WOW().init();

  // blinkingPattern('.triangle');

  $(".case-carousel-body").slick({
    prevArrow: $(".arrow-prev"),
    nextArrow: $(".arrow-next"),
    fade: true,
    speed: 1000,
    dots: true,
    customPaging : function(slider, i) {
      var thumb = $(slider.$slides[i]).data();
      return '<button>'+'0'+ (i +1) +'</button>';
              },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  });

  if (window.matchMedia("(min-width: 1199px)").matches) {
    $(".slick-clients").slick({
      speed: 1000,
      infinite: true,
      dots: false,
      arrows: false,
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
    });
  }

  let header = new Header();

  var scene = document.getElementById("scene");

  if (scene && !helpers.isMobile) {
    var parallaxInstance = new Parallax(scene);
  }

  const confettiConfig = {
    target: "confetti-holder",
    max: "200",
    size: ".5",
    animate: true,
    props: [
      {
        type: "svg",
        src: helpers.wpVars.templateURL + "/images/polygon-confetti-3.svg",
        size: 40,
        weight: 1,
      },
    ],
    clock: "15",
    rotate: false,
    width: window.innerWidth / 1.8,
    height: window.innerHeight,
    respawn: true,
  };

  if (document.getElementById("confetti-holder")) {
    const confetti = new ConfettiGenerator(confettiConfig);
    confetti.render();
  }

  const objMouseMove = document.querySelectorAll(".phone-slick, .case-mockup");
  let mvmnt = true;

  if (objMouseMove !== null) {
    document.addEventListener("mousemove", function (ev) {
      if (mvmnt) {
        setTimeout(function () {
          var cX = ev.clientX;
          var sX = ev.screenX;
          var cY = ev.clientY;
          var sY = ev.screenY;
          objMouseMove.forEach((item) => {
            item.style.transform =
              "translate(" +
              -1 * (cX / 30) +
              "px," +
              -1 * (cY / 35) +
              "px" +
              ")";
          });
          mvmnt = true;
        }, 250);
        mvmnt = false;
      }
    });
  }

  $(window).scroll(function() {
    var hT = $('#results').offset().top,
        hH = $('#results').outerHeight(),
        wH = $(window).height(),
        wS = $(this).scrollTop();
    if (wS > ((hT+hH-wH)-140)){
        numberCountHandler();
    }
  });

  const backToTop = document.querySelectorAll("#back-to-top");

  backToTop.forEach(item => {
    item.addEventListener('click', topFunction)
  });

  createAnimations();
};

// window load binds
window.onload = init;

// function DOMLoaded() {
//     // these are not always necessary but sometimes they fuck with ya
//     if (helpers.iOS) {
//         document.querySelector('html').classList.add('ios');
//     } else if (helpers.IE()) {
//         document.querySelector('html').classList.add('ie');
//     }
// }

// // domcontent binds
// document.addEventListener('DOMContentLoaded', DOMLoaded);
